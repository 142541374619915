<template>
  <span>
    <v-text-field
      v-show="!edit"
      :append-icon="mdiPencil"
      readonly
      :value="score"
      class="shrink"
      style="width: 65px"
      @click:append="editScore"
    >
    </v-text-field>
    <v-text-field
      v-show="edit"
      autofocus
      ref="editValue"
      :append-icon="mdiCheck"
      v-model="newScore"
      class="shrink"
      style="width: 65px"
      @click:append="saveScore"
      :loading="loading"
    >
    </v-text-field>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { mdiCheck, mdiPencil } from '@mdi/js'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      mdiPencil: mdiPencil,
      mdiCheck: mdiCheck,
      edit: false,
      loading: false,
      newScore: this.score,
    }
  },
  methods: {
    ...mapActions('content', ['updateContent', 'publishContent']),
    ...mapMutations('content', ['updateQuestionScore']),
    editScore() {
      this.edit = true
      this.newScore = this.score
      this.$refs.editValue.$el.focus()
    },
    saveScore() {
      console.log('score', this.newScore)
      this.loading = true
      this.updateQuestionScore({ id: this.id, score: Number(this.newScore) })
      this.updateContent({ autoPublish: true }).then((__) => {
        //this.score = this.newScore;
        this.loading = false
        this.edit = false
      })
    },
  },
}
</script>

<style></style>
