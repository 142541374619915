<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="libraryProblems"
        :footer-props.sync="footerOptions"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <router-link
                class="ml-1 problem_link"
                :to="'/problems/' + item.getUrl()"
              >
                <v-col>
                  {{ item.getTitle() }}
                </v-col>
              </router-link>
            </td>
            <td>
              {{ getDifficulty(item) }}
            </td>
            <td>Algorithm</td>
            <td>
              <v-chip
                outlined
                color="grey lighten-2"
                text-color="grey"
                class="mr-2"
                v-for="t in getTags(item)"
                label
                :key="t"
                @click="searchTag"
              >
                {{ t }}
              </v-chip>
            </td>
            <td v-if="showAddToTest">
              <problem-card
                :problem="item"
                :selectedProblems="selectedProblems"
              ></problem-card>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ProblemCard from './ProblemCard.vue'
export default {
  props: {
    libraryProblems: {
      type: Array,
      required: true,
    },
    selectedProblems: {
      type: Array,
      required: true,
    },
    showAddToTest: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      footerOptions: {
        itemsPerPageOptions: [10, 25, 50],
      },
      search: null,
    }
  },
  computed: {
    headers() {
      var h = [
        {
          text: 'Problem',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Difficulty',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Skills',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Tags',
          align: 'left',
          sortable: false,
        },
      ]
      if (this.showAddToTest) {
        h.push({
          text: 'Status',
          align: 'left',
          sortable: false,
        })
      }
      return h
    },
  },
  methods: {
    getDifficulty(item) {
      var t = item.getMetaData().getTagsList()
      var d = t.find((t) => t.startsWith('difficulties/'))
      if (!d) return ''
      d = d.substring(13)
      d = d.charAt(0).toUpperCase() + d.substring(1)
      return d
    },
    getTags(item) {
      var t = item.getMetaData().getTagsList()
      return t.filter((t) => t.startsWith('topics/')).map((t) => t.substring(7))
    },
    searchTag(e) {
      this.search = e.target.innerText
    },
  },
  components: {
    ProblemCard,
  },
}
</script>

<style scoped>
.problem_link {
  text-decoration: none;
  color: black;
}
</style>
