var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "px-4 py-5"
  }, [_c('v-card', {
    staticClass: "mx-auto my-auto",
    attrs: {
      "outlined": "",
      "max-height": "300"
    }
  }, [_c('v-row', {
    staticClass: "mx-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-6"
  }, [_c('CodeMirror', {
    ref: "myCm",
    staticClass: "markdown",
    class: _vm.fontSize,
    attrs: {
      "value": _vm.code,
      "options": _vm.cmOptions
    },
    on: {
      "ready": _vm.onCmReady,
      "focus": _vm.onCmFocus,
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }