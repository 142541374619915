var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', {
    staticClass: "mt-2 mb-2 d-flex"
  }, [_c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "col": 3
    }
  }, [_c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.dialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "height": "35"
          }
        }, on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(_vm.mdiPlus))]), _vm._v(" Add MCQ Question ")], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    ref: "libraryDialog",
    attrs: {
      "raised": "",
      "width": "700px"
    }
  }, [_c('v-card-text', {
    staticClass: "pt-5"
  }, [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-h7"
  }, [_vm._v(" Question statement (in markdown) ")]), _c('MarkdownEditor', {
    attrs: {
      "editorialMode": false,
      "submissionMode": true,
      "code": _vm.statement,
      "onCodeChange": _vm.onStatementChange,
      "value": _vm.editorConfig
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Options")]), _c('v-btn', {
    staticClass: "ml-5",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.addOption
    }
  }, [_vm._v("Add Option")])], 1)], 1), _c('v-form', {
    ref: "mcqAdd",
    model: {
      value: _vm.mcqAddValid,
      callback: function ($$v) {
        _vm.mcqAddValid = $$v;
      },
      expression: "mcqAddValid"
    }
  }, _vm._l(_vm.options, function (opt, idx) {
    return _c('v-row', {
      key: idx
    }, [_c('v-col', {
      staticClass: "mcq_option",
      attrs: {
        "cols": 8
      }
    }, [_c('MarkdownEditor', {
      attrs: {
        "editorialMode": false,
        "submissionMode": true,
        "code": opt.statement,
        "onCodeChange": function (v) {
          return _vm.optionStatement(idx, v);
        },
        "value": _vm.editorConfig
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": 4
      }
    }, [_c('v-switch', {
      attrs: {
        "label": "Correct Answer",
        "rules": _vm.singleOptionCorrectRule
      },
      on: {
        "change": function (v) {
          return _vm.setCorrectAnswer(v, idx);
        }
      },
      model: {
        value: _vm.options[idx].isAnswer,
        callback: function ($$v) {
          _vm.$set(_vm.options[idx], "isAnswer", $$v);
        },
        expression: "options[idx].isAnswer"
      }
    }), _c('v-btn', {
      staticClass: "ml-5",
      attrs: {
        "small": "",
        "color": "error",
        "disabled": !(_vm.options.length > 2)
      },
      on: {
        "click": function ($event) {
          return _vm.deleteOption(idx);
        }
      }
    }, [_vm._v(" Delete ")])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "accent",
      "loading": _vm.creating,
      "disabled": !_vm.mcqAddValid
    },
    on: {
      "click": _vm.create
    }
  }, [_vm._v(" Create ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }