<template>
  <span>
    <v-btn
      v-if="!isAlreadyAdded"
      class="ma-2"
      color="accent"
      prominent
      outlined
      @click="addProblem(problem.getId())"
      :loading="addToTestLoading"
    >
      Add To Test
      <template v-slot:loader>
        <span>Adding ...</span>
      </template>
    </v-btn>
    <v-btn
      v-else
      class="ma-2"
      color="warning"
      prominent
      outlined
      @click="removeSelectedProblem(problem.getId())"
      :loading="removeFromTestLoading"
    >
      Remove from Test
      <template v-slot:loader>
        <span>Removing ...</span>
      </template>
    </v-btn>
  </span>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
  props: {
    problem: {
      type: Object,
      required: true,
    },
    selectedProblems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loader: null,
      loading: false,
      addToTestLoading: false,
      removeFromTestLoading: false,
    }
  },
  computed: {
    ...mapState('content', ['updateContentStatus', 'content']),
    isAlreadyAdded() {
      return this.selectedProblems.some(
        (p) => p.getId() === this.problem.getId(),
      )
    },
  },
  methods: {
    ...mapMutations('content', ['setTestProblems', 'removeProblem']),
    ...mapActions('content', ['updateContent', 'publishContent']),
    addProblem(problemId) {
      console.log('Problem Id to add .....???', problemId)
      this.addToTestLoading = true
      var newProblems = this.selectedProblems.map((p) => {
        console.log('problem ..///..', p)
        return p.getId()
      })
      newProblems.push(problemId)
      console.log('New selected problems', newProblems)
      this.setTestProblems(newProblems)
      this.updateContent({ autoPublish: true }).then((__) => {
        this.addToTestLoading = false
      })
    },
    removeSelectedProblem(problemId) {
      console.log('Remove', problemId)
      this.removeFromTestLoading = true
      this.removeProblem(problemId)
      this.updateContent({ autoPublish: true }).then((__) => {
        this.removeFromTestLoading = false
      })
    },
  },
}
</script>
<style scoped>
.problem_link {
  text-decoration: none;
  color: black;
}
</style>
