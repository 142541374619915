<template>
  <page
    :title="contentView ? contentView.getTitle() : ''"
    :apiStatusList="[
      fetchContentViewStatus,
      listTestProblemStatus,
      fetchAllContentUserActivityStatus,
    ]"
    :breadcrumbs="breadcrumbs"
    minimal
  >
    <v-tabs color="accent" slider-color="accent" v-model="testView">
      <v-tab>Test</v-tab>
      <v-tab>Candidates</v-tab>
      <v-tab>Settings</v-tab>
    </v-tabs>
    <v-tabs-items v-model="testView">
      <v-tab-item>
        <v-row>
          <v-col>
            <v-tabs v-model="questionView">
              <v-tab>Questions</v-tab>
              <v-tab>MCQs</v-tab>
            </v-tabs>
            <v-tabs-items v-model="questionView">
              <v-tab-item>
                <v-row class="mt-2 mb-2 d-flex">
                  <v-spacer></v-spacer>
                  <v-col :col="3" class="d-flex justify-end">
                    <v-dialog
                      transition="dialog-bottom-transition"
                      v-model="dialog"
                      @keydown.esc="dialog = false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="accent" height="35" v-on="on">
                          <v-icon class="mr-2" small>{{ mdiPlus }}</v-icon>
                          Add Problem
                        </v-btn>
                      </template>
                      <v-card ref="libraryDialog" raised>
                        <v-card-title>
                          <v-row class="d-flex">
                            <v-col class="text-caption-1" :cols="3">
                              Problem Library
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col :cols="3" class="d-flex justify-end">
                              <v-btn color="accent" @click="dialog = false">
                                Done
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <problem-library
                          :libraryProblems="problems"
                          :selectedProblems="selectedProblems"
                          :showAddToTest="true"
                        ></problem-library>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <problem-list :problems="problemQuestions"></problem-list>
              </v-tab-item>
              <v-tab-item>
                <mcq-dialog></mcq-dialog>
                <v-data-table
                  :hide-default-footer="true"
                  :headers="mcqHeaders"
                  :items="mcqs"
                >
                  <template v-slot:item="{ item }">
                    <tr class="px-0 py-0">
                      <td>
                        {{ item.statement }}
                      </td>
                      <td>
                        <question-score
                          :id="item.idx"
                          :score="item.score"
                        ></question-score>
                      </td>
                      <td>
                        <v-icon @click="deleteMcq(item.idx)">{{
                          mdiDelete
                        }}</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <v-file-input
              accept="*"
              label="Select a csv file including candidates details, in format of name, email"
              v-model="candidatesCsv"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="accent"
              class="mr-4"
              :loading="invitesLoading"
              height="35"
              @click="saveCandidatesCsv"
              >Save</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="d-flex">
          <v-col class="d-flex justify-end">
            <v-dialog v-model="inviteDailog" @keydown.esc="reset">
              <template v-slot:activator="{ on }">
                <v-btn color="accent" height="35" v-on="on">
                  <v-icon class="mr-2" small>{{ mdiAccountPlus }}</v-icon>
                  Invite Candiate
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <v-row class="d-flex">
                    <v-col cols="8" class="text-caption-1">
                      Invite Candidate
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex justify-end">
                      <v-btn
                        :disabled="!valid"
                        color="accent"
                        class=""
                        :loading="inviteLoading"
                        @click="sentInvite"
                        >Invite</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="name"
                      :counter="255"
                      :rules="nameRules"
                      label="Name"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <content-user-activity-list
              :allContentUserActivity="allContentUserActivity"
              :url="url"
            ></content-user-activity-list>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="mt-2">
          <v-col>
            <span
              >Test Duration : {{ this.durationInHours }} Hours
              {{ this.durationInMinutes }} Mins
            </span>
            <v-dialog
              v-model="durationDialog"
              @keydown.esc="durationDialog = false"
            >
              <template v-slot:activator="{ on }">
                <v-icon small class="mb-1" v-on="on">{{ mdiPencil }}</v-icon>
              </template>
              <v-card>
                <v-card-title>
                  <v-row class="d-flex">
                    <v-col class="text-caption-1" :cols="3">
                      Change Duration
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col :cols="3" class="d-flex justify-end">
                      <v-btn
                        color="accent"
                        class="mr-4"
                        @click="changeDuration"
                        :loading="durationLoading"
                        >Update</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-form ref="durationForm">
                    <v-text-field
                      v-model="durationInHours"
                      label="Hours"
                      required
                      :rules="hoursRules"
                    ></v-text-field>
                    <v-text-field
                      v-model="durationInMinutes"
                      label="Mins"
                      required
                      :rules="minutesRules"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="4">
            <v-select
              v-model="testTypeSelection"
              item-text="text"
              item-value="type"
              :items="testTypes"
              label="Test Type"
              @change="setLocalTestType"
            ></v-select>
          </v-col>
        </v-row>
        <div v-if="testTypeSelection === 'TEST_MODE_RANDOM_SELECTION'">
          <div>
            <v-row>
              <v-col :cols="8">
                Select problem counts
                <v-form
                  v-model="questionCountValid"
                  ref="questionCount"
                  class="d-flex justify-space-around"
                >
                  <v-select
                    label="Select difficulty"
                    :items="Object.keys(problemTypeCounts)"
                    v-model="selectedQuestionType"
                    :rules="[(v) => !!v || 'Item is required']"
                  >
                  </v-select>
                  <v-text-field
                    :rules="validNumberRule"
                    label="count"
                    v-model="selectedQuestionTypeCount"
                    class="d-flex px-2"
                    type="number"
                  ></v-text-field>
                  <v-btn
                    :disabled="!questionCountValid"
                    color="info"
                    class="d-flex px-2"
                    @click="submitQuestionsCount"
                  >
                    Add
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="8">
                Select MCQ counts
                <v-form
                  v-model="mcqCountValid"
                  ref="mcqFormCount"
                  class="d-flex justify-space-around"
                >
                  <v-text-field
                    :rules="validNumberRule"
                    class="d-flex px-2"
                    v-model="mcqMarks"
                    type="number"
                    label="marks"
                  >
                  </v-text-field>
                  <v-text-field
                    :rules="validNumberRule"
                    class="d-flex px-2"
                    v-model="mcqCount"
                    type="number"
                    label="count"
                  >
                  </v-text-field>
                  <v-btn
                    :disabled="!mcqCountValid"
                    color="info"
                    @click="updateMcqQuestions"
                  >
                    Add
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </div>
          <div class="py-5">
            <h3>All Questions</h3>
            <v-data-table
              :headers="allQuestionsHeader"
              :items="allQuestionsInCurrentTest"
              :items-per-page="15"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-dialog v-model="qEditDialog">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ currentAction }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container> Stuff Here </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="info darken-1"
                        text
                        @click="qEditDialog = !qEditDialog"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="success darken-1"
                        text
                        @click="() => {}"
                        v-if="currentAction === 'edit'"
                      >
                        Save
                      </v-btn>
                      <v-btn
                        color="error darken-1"
                        text
                        @click="() => {}"
                        v-if="currentAction === 'delete'"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  class="mr-2"
                  color="info"
                  @click="editRandomSubsetMcq(item)"
                >
                  Edit
                </v-btn>
                <v-btn
                  small
                  class="mr-2"
                  color="error"
                  @click="deleteRandomSubsetMcq(item)"
                >
                  Delete
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
        <div v-else-if="testTypeSelection === 'TEST_MODE_EXACT'">
          <span> Exact Test </span>
        </div>
        <!-- TEST_MODE_UNDEFINED -->
        <div v-else>
          <span>Please select an option to get more detailed choices</span>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import ProblemList from '../components/hiring/ProblemList'
import Page from '../components/Page'
import ProblemLibrary from '../components/hiring/ProblemLibrary'
import McqDialog from '../components/hiring/McqDialog'
import ContentUserActivityList from '../components/hiring/ContentUserActivityList'
import moment, { duration } from 'moment'
import {
  mdiDelete,
  mdiPlus,
  mdiClose,
  mdiPencil,
  mdiSend,
  mdiAccountPlus,
} from '@mdi/js'
import QuestionScore from '../components/hiring/QuestionScore.vue'

var content_proto = proto.io.codedrills.proto.content

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      candidatesCsv: null,
      mdiClose: mdiClose,
      mdiPencil: mdiPencil,
      mdiPlus: mdiPlus,
      mdiSend: mdiSend,
      mdiDelete: mdiDelete,
      mdiAccountPlus: mdiAccountPlus,
      dialog: false,
      inviteDailog: false,
      durationDialog: false,
      testView: null,
      questionView: null,
      mcqHeaders: [
        { text: 'Question', value: 'question' },
        { text: 'Action', value: 'action' },
      ],
      valid: true,
      name: '',
      email: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length <= 255) || 'Name must be less than 255 characters',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      hoursRules: [
        (v) => (v >= 0 && v <= 5) || 'Hour should be between 0 and 5',
        (__) =>
          this.durationInHours > 0 ||
          this.durationInMinutes > 0 ||
          'Duration should be greater than 0',
      ],
      minutesRules: [
        (v) => (v >= 0 && v <= 59) || 'Hour should be between 0 and 59',
        (__) =>
          this.durationInHours > 0 ||
          this.durationInMinutes > 0 ||
          'Duration should be greater than 0',
      ],
      select: null,
      checkbox: false,
      durationInHours: 0,
      durationInMinutes: 0,
      durationLoading: false,
      inviteLoading: false,
      invitesLoading: false,
      questionCountValid: false,
      testTypeSelection: 'TEST_MODE_UNDEFINED',
      testTypes: [
        {
          type: 'TEST_MODE_EXACT',
          text: 'Exact',
        },
        {
          type: 'TEST_MODE_RANDOM_SELECTION',
          text: 'Random Subset',
        },
      ],
      problemTypeCounts: {
        easy: 0,
        medium: 0,
        hard: 0,
      },
      selectedQuestionType: null,
      selectedQuestionTypeCount: 0,
      allQuestionsHeader: [
        { class: 'text-overline', text: 'Type', value: 'type' },
        { class: 'text-overline', text: 'Rating', value: 'rating' },
        { class: 'text-overline', text: 'Count', value: 'count' },
        { class: 'text-overline', text: 'Actions', value: 'actions' },
      ],
      allQuestionsInCurrentTest: [],
      mcqCount: 0,
      mcqMarks: 0,
      mcqCountValid: false,
      validNumberRule: [
        (v) => (Number.isInteger(Number(v)) && v > 0) || 'Enter valid number',
      ],
      // to be decided
      questionCountRule: [
        (v) => (v >= 0 && v <= 10) || 'Count should be between 0 and 10',
      ],
      qEditDialog: false,
      currentQActionItem: null,
      currentAction: null,
    }
  },
  computed: {
    ...mapState('content', [
      'content',
      'contentView',
      'fetchContentViewStatus',
      'fetchUserContentPreviewsStatus',
      'fetchAllContentUserActivityStatus',
      'userContentPreviews',
      'allContentUserActivity',
      'fetchContentStatus',
      'testCandidates',
    ]),
    ...mapState('hiring', ['problems', 'listTestProblemStatus']),
    duration() {
      var time = 0 //todo add total time of all problems
      return time
    },
    selectedProblems() {
      return (
        this.contentView &&
        this.contentView
          .getChildPreviewsList()
          .map((cp) => cp.getContentPreview())
      )
    },
    problemQuestions() {
      if (
        !this.content ||
        !this.content.getData ||
        !this.contentView ||
        !this.content.getData().getTest().getTestQuestionList
      )
        return []
      var cps = this.selectedProblems
      var problems = []
      for (var i = 0; i < cps.length; ++i) {
        var id = cps[i].getId()
        var tq = this.content
          .getData()
          .getTest()
          .getTestQuestionList()
          .find((tq) => tq.getProblem() && tq.getProblem().getContentId() == id)
        if (tq) {
          problems.push({
            contentPreview: cps[i],
            question: tq,
          })
        }
      }
      console.log('problems', problems)
      return problems
    },
    mcqs() {
      if (
        !this.content ||
        !this.content.getData().getTest().getTestQuestionList
      )
        return []
      var allMcqs = []
      for (
        var idx = 0;
        idx < this.content.getData().getTest().getTestQuestionList().length;
        ++idx
      ) {
        var tq = this.content.getData().getTest().getTestQuestionList()[idx]
        if (tq.getMcq()) {
          allMcqs.push({
            idx: tq.getQuestionId(),
            statement: tq.getMcq().getStatement(),
            score: tq.getScore(),
          })
        }
      }
      return allMcqs
    },
    breadcrumbs() {
      var crumbs = []
      crumbs.push({
        text: 'Hiring Dashboard',
        href: '/hiring/dashboard',
      })
      if (this.contentView) {
        crumbs.push({
          text: this.contentView.getTitle(),
          href: '/hiring/tests/' + this.contentView.getUrl(),
          disabled: true,
        })
      }

      return crumbs
    },
  },
  methods: {
    ...mapGetters('content', [
      'getTestTypes',
      'getContestProblems',
      'getContestMcqs',
    ]),
    ...mapActions('content', [
      'fetchContentView',
      'fetchUserContentPreviews',
      'fetchAllContentUserActivity',
      'inviteCandidate',
      'updateTestDuration',
      'fetchContent',
      'updateContent',
      'publishContent',
      'inviteCandidates',
      'fetchTestCandidates',
    ]),
    ...mapActions('hiring', ['listTestProblem']),
    ...mapMutations('content', [
      'removeMcq',
      'setAllContestQuestions',
      'setDefaultContent',
    ]),
    sentInvite(name, email) {
      if (!this.$refs.form.validate()) return
      this.inviteLoading = true
      this.inviteCandidate({
        id: this.contentView.getId(),
        name: this.name,
        email: this.email,
      }).then((__) => {
        this.inviteLoading = false
        this.inviteDailog = false
      })
    },
    reset() {
      this.$refs.form.reset()
    },
    changeDuration() {
      if (!this.$refs.durationForm.validate()) return
      this.durationLoading = true
      var duration =
        this.durationInHours * 60 * 60 + this.durationInMinutes * 60
      this.updateTestDuration({
        id: this.contentView.getId(),
        durationInS: duration,
      }).then((_) => {
        this.durationLoading = false
        this.durationDialog = false
      })
    },
    deleteMcq(id) {
      console.log('Deleting', id)
      this.removeMcq(id)
      this.updateContent({ autoPublish: true }).then((__) => {})
    },
    setLocalTestType(test) {
      this.testTypeSelection = test
      this.setupQuestionsTable()

      this.setDefaultContent()
      this.$nextTick(() => {
        this.saveContestQuestions()
        console.log(`checking defaults`, this.content.getData().getTest())
      })
      // other places led to undefined errors since content
      // wasnt loaded yet
      this.content
        .getData()
        .getTest()
        .getTestSettings()
        .setTestMode(this.getTestTypes()[test])

      console.log(
        Object.values(this.problemTypeCounts),
        this.allQuestionsInCurrentTest,
      )
    },
    saveContestQuestions() {
      console.log('Saving')
      this.setAllContestQuestions(this.allQuestionsInCurrentTest)
      this.updateContent({ autoPublish: true }).then((__) => {})
    },
    setRandomTestQuestionCount(diff) {
      // no need to add 1 since value gets upgraded in place
      // this is solely for reactivity
      this.$set(
        this.problemTypeCounts,
        diff,
        Number(this.problemTypeCounts[diff]),
      )
      // this.setAllContestQuestions(this.problemTypeCounts);
    },
    setupQuestionsTable() {
      this.problemTypeCounts = this.getContestProblems()
      for (const k in this.problemTypeCounts) {
        this.allQuestionsInCurrentTest.push({
          type: 'Problem',
          rating: k,
          count: this.problemTypeCounts[k],
        })
      }
      let mcqQuestions = this.getContestMcqs()
      for (const k in mcqQuestions) {
        this.allQuestionsInCurrentTest.push({
          type: 'MCQ',
          rating: k,
          count: mcqQuestions[k],
        })
      }
    },
    submitQuestionsCount() {
      this.$refs.questionCount.validate()
      // ^ this returns if not valid so cannot submit invalid details

      let alrExistingProblemDiff = false
      if (this.allQuestionsInCurrentTest.length > 0) {
        this.allQuestionsInCurrentTest.map((curr, idx) => {
          if (
            curr.type === 'Problem' &&
            curr.rating === this.selectedQuestionType
          ) {
            const curr_count = curr.count
            this.$set(this.allQuestionsInCurrentTest, idx, {
              type: 'Problem',
              rating: this.selectedQuestionType,
              count:
                Number(curr_count) + Number(this.selectedQuestionTypeCount),
            })
            alrExistingProblemDiff = true
            console.log(this.allQuestionsInCurrentTest[idx])
          }
        })
      }
      if (!alrExistingProblemDiff) {
        for (const k in this.problemTypeCounts) {
          this.allQuestionsInCurrentTest.push({
            type: 'Problem',
            rating: k,
            count: this.problemTypeCounts[k],
          })
        }
      }
      this.$refs.questionCount.resetValidation()
      this.saveContestQuestions()
    },
    updateMcqQuestions() {
      let alrExistingMcqRating = false
      if (this.allQuestionsInCurrentTest.length > 0) {
        this.allQuestionsInCurrentTest.map((curr, idx) => {
          // inserting (this.mcqMarks, this.mcqCount)
          if (curr.type === 'MCQ' && curr.rating === this.mcqMarks) {
            this.$set(this.allQuestionsInCurrentTest, idx, {
              type: 'MCQ',
              rating: this.mcqMarks,
              count: Number(curr.count) + Number(this.mcqCount),
            })
            alrExistingMcqRating = true
          }
        })
      }
      if (!alrExistingMcqRating) {
        this.allQuestionsInCurrentTest.push({
          type: 'MCQ',
          rating: this.mcqMarks,
          count: this.mcqCount,
        })
      }
      // this.mcqMarks = 0;
      // this.mcqCount = 0;
      this.$refs.mcqFormCount.resetValidation()
      this.saveContestQuestions()
    },
    editRandomSubsetMcq(item) {
      this.currentAction = 'edit'
      console.log('edit', item)
      this.currentQActionItem = item
      this.qEditDialog = true
    },
    deleteRandomSubsetMcq(item) {
      this.currentAction = 'delete'
      console.log('delete', item)
      this.currentQActionItem = item
      this.qEditDialog = true
    },
    async saveCandidatesCsv() {
      console.log('Saving csv ..')
      this.invitesLoading = true
      await this.candidatesCsv.text().then((content) => {
        this.inviteCandidates({
          candidatesCsv: content,
          url: this.content.getId(),
        }).then((__) => {
          this.clearCandidatesCsv()
          this.invitesLoading = false
          this.$router
            .push('/hiring/tests/' + this.content.getUrl())
            .catch((__) => {})
        })
      })
    },

    clearCandidatesCsv() {
      this.candidatesCsv = null
    },
  },
  created() {
    this.fetchContentView({
      parentType: null,
      parentUrl: null,
      type: 'TEST',
      url: this.url,
    }).then((__) => {
      var now = moment()
      var value = this.contentView.getMetaData().getTestMeta().getDurationInS()
      this.durationInMinutes = moment.duration(value * 1000).minutes()
      this.durationInHours = Math.trunc(moment.duration(value * 1000).asHours())

      this.fetchAllContentUserActivity({ contentId: this.contentView.getId() })
      document.title = this.contentView.getTitle() + ' - CodeDrills'
      this.fetchContent({ id: this.contentView.getId() })
    })
    // this.fetchUserContentPreviews({type: 'PROBLEM', tags: ['types/interview']});
    this.listTestProblem()
  },
  components: {
    ProblemList,
    Page,
    ProblemLibrary,
    ContentUserActivityList,
    McqDialog,
    QuestionScore,
  },
}
</script>
