var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.contentView ? _vm.contentView.getTitle() : '',
      "apiStatusList": [_vm.fetchContentViewStatus, _vm.listTestProblemStatus, _vm.fetchAllContentUserActivityStatus],
      "breadcrumbs": _vm.breadcrumbs,
      "minimal": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "color": "accent",
      "slider-color": "accent"
    },
    model: {
      value: _vm.testView,
      callback: function ($$v) {
        _vm.testView = $$v;
      },
      expression: "testView"
    }
  }, [_c('v-tab', [_vm._v("Test")]), _c('v-tab', [_vm._v("Candidates")]), _c('v-tab', [_vm._v("Settings")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.testView,
      callback: function ($$v) {
        _vm.testView = $$v;
      },
      expression: "testView"
    }
  }, [_c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-tabs', {
    model: {
      value: _vm.questionView,
      callback: function ($$v) {
        _vm.questionView = $$v;
      },
      expression: "questionView"
    }
  }, [_c('v-tab', [_vm._v("Questions")]), _c('v-tab', [_vm._v("MCQs")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.questionView,
      callback: function ($$v) {
        _vm.questionView = $$v;
      },
      expression: "questionView"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "mt-2 mb-2 d-flex"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "col": 3
    }
  }, [_c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.dialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "height": "35"
          }
        }, on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(_vm.mdiPlus))]), _vm._v(" Add Problem ")], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    ref: "libraryDialog",
    attrs: {
      "raised": ""
    }
  }, [_c('v-card-title', [_c('v-row', {
    staticClass: "d-flex"
  }, [_c('v-col', {
    staticClass: "text-caption-1",
    attrs: {
      "cols": 3
    }
  }, [_vm._v(" Problem Library ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": 3
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Done ")])], 1)], 1)], 1), _c('problem-library', {
    attrs: {
      "libraryProblems": _vm.problems,
      "selectedProblems": _vm.selectedProblems,
      "showAddToTest": true
    }
  })], 1)], 1)], 1)], 1), _c('problem-list', {
    attrs: {
      "problems": _vm.problemQuestions
    }
  })], 1), _c('v-tab-item', [_c('mcq-dialog'), _c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.mcqHeaders,
      "items": _vm.mcqs
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', {
          staticClass: "px-0 py-0"
        }, [_c('td', [_vm._v(" " + _vm._s(item.statement) + " ")]), _c('td', [_c('question-score', {
          attrs: {
            "id": item.idx,
            "score": item.score
          }
        })], 1), _c('td', [_c('v-icon', {
          on: {
            "click": function ($event) {
              return _vm.deleteMcq(item.idx);
            }
          }
        }, [_vm._v(_vm._s(_vm.mdiDelete))])], 1)])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    attrs: {
      "accept": "*",
      "label": "Select a csv file including candidates details, in format of name, email"
    },
    model: {
      value: _vm.candidatesCsv,
      callback: function ($$v) {
        _vm.candidatesCsv = $$v;
      },
      expression: "candidatesCsv"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "accent",
      "loading": _vm.invitesLoading,
      "height": "35"
    },
    on: {
      "click": _vm.saveCandidatesCsv
    }
  }, [_vm._v("Save")])], 1)], 1), _c('v-row', {
    staticClass: "d-flex"
  }, [_c('v-col', {
    staticClass: "d-flex justify-end"
  }, [_c('v-dialog', {
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.reset.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "height": "35"
          }
        }, on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(_vm.mdiAccountPlus))]), _vm._v(" Invite Candiate ")], 1)];
      }
    }]),
    model: {
      value: _vm.inviteDailog,
      callback: function ($$v) {
        _vm.inviteDailog = $$v;
      },
      expression: "inviteDailog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    staticClass: "d-flex"
  }, [_c('v-col', {
    staticClass: "text-caption-1",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" Invite Candidate ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.valid,
      "color": "accent",
      "loading": _vm.inviteLoading
    },
    on: {
      "click": _vm.sentInvite
    }
  }, [_vm._v("Invite")])], 1)], 1)], 1), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "counter": 255,
      "rules": _vm.nameRules,
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "label": "E-mail",
      "required": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('content-user-activity-list', {
    attrs: {
      "allContentUserActivity": _vm.allContentUserActivity,
      "url": _vm.url
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', [_c('span', [_vm._v("Test Duration : " + _vm._s(this.durationInHours) + " Hours " + _vm._s(this.durationInMinutes) + " Mins ")]), _c('v-dialog', {
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.durationDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-icon', _vm._g({
          staticClass: "mb-1",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(_vm._s(_vm.mdiPencil))])];
      }
    }]),
    model: {
      value: _vm.durationDialog,
      callback: function ($$v) {
        _vm.durationDialog = $$v;
      },
      expression: "durationDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    staticClass: "d-flex"
  }, [_c('v-col', {
    staticClass: "text-caption-1",
    attrs: {
      "cols": 3
    }
  }, [_vm._v(" Change Duration ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": 3
    }
  }, [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "accent",
      "loading": _vm.durationLoading
    },
    on: {
      "click": _vm.changeDuration
    }
  }, [_vm._v("Update")])], 1)], 1)], 1), _c('v-card-text', [_c('v-form', {
    ref: "durationForm"
  }, [_c('v-text-field', {
    attrs: {
      "label": "Hours",
      "required": "",
      "rules": _vm.hoursRules
    },
    model: {
      value: _vm.durationInHours,
      callback: function ($$v) {
        _vm.durationInHours = $$v;
      },
      expression: "durationInHours"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Mins",
      "required": "",
      "rules": _vm.minutesRules
    },
    model: {
      value: _vm.durationInMinutes,
      callback: function ($$v) {
        _vm.durationInMinutes = $$v;
      },
      expression: "durationInMinutes"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-select', {
    attrs: {
      "item-text": "text",
      "item-value": "type",
      "items": _vm.testTypes,
      "label": "Test Type"
    },
    on: {
      "change": _vm.setLocalTestType
    },
    model: {
      value: _vm.testTypeSelection,
      callback: function ($$v) {
        _vm.testTypeSelection = $$v;
      },
      expression: "testTypeSelection"
    }
  })], 1)], 1), _vm.testTypeSelection === 'TEST_MODE_RANDOM_SELECTION' ? _c('div', [_c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 8
    }
  }, [_vm._v(" Select problem counts "), _c('v-form', {
    ref: "questionCount",
    staticClass: "d-flex justify-space-around",
    model: {
      value: _vm.questionCountValid,
      callback: function ($$v) {
        _vm.questionCountValid = $$v;
      },
      expression: "questionCountValid"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Select difficulty",
      "items": Object.keys(_vm.problemTypeCounts),
      "rules": [function (v) {
        return !!v || 'Item is required';
      }]
    },
    model: {
      value: _vm.selectedQuestionType,
      callback: function ($$v) {
        _vm.selectedQuestionType = $$v;
      },
      expression: "selectedQuestionType"
    }
  }), _c('v-text-field', {
    staticClass: "d-flex px-2",
    attrs: {
      "rules": _vm.validNumberRule,
      "label": "count",
      "type": "number"
    },
    model: {
      value: _vm.selectedQuestionTypeCount,
      callback: function ($$v) {
        _vm.selectedQuestionTypeCount = $$v;
      },
      expression: "selectedQuestionTypeCount"
    }
  }), _c('v-btn', {
    staticClass: "d-flex px-2",
    attrs: {
      "disabled": !_vm.questionCountValid,
      "color": "info"
    },
    on: {
      "click": _vm.submitQuestionsCount
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 8
    }
  }, [_vm._v(" Select MCQ counts "), _c('v-form', {
    ref: "mcqFormCount",
    staticClass: "d-flex justify-space-around",
    model: {
      value: _vm.mcqCountValid,
      callback: function ($$v) {
        _vm.mcqCountValid = $$v;
      },
      expression: "mcqCountValid"
    }
  }, [_c('v-text-field', {
    staticClass: "d-flex px-2",
    attrs: {
      "rules": _vm.validNumberRule,
      "type": "number",
      "label": "marks"
    },
    model: {
      value: _vm.mcqMarks,
      callback: function ($$v) {
        _vm.mcqMarks = $$v;
      },
      expression: "mcqMarks"
    }
  }), _c('v-text-field', {
    staticClass: "d-flex px-2",
    attrs: {
      "rules": _vm.validNumberRule,
      "type": "number",
      "label": "count"
    },
    model: {
      value: _vm.mcqCount,
      callback: function ($$v) {
        _vm.mcqCount = $$v;
      },
      expression: "mcqCount"
    }
  }), _c('v-btn', {
    attrs: {
      "disabled": !_vm.mcqCountValid,
      "color": "info"
    },
    on: {
      "click": _vm.updateMcqQuestions
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "py-5"
  }, [_c('h3', [_vm._v("All Questions")]), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.allQuestionsHeader,
      "items": _vm.allQuestionsInCurrentTest,
      "items-per-page": 15
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-dialog', {
          model: {
            value: _vm.qEditDialog,
            callback: function ($$v) {
              _vm.qEditDialog = $$v;
            },
            expression: "qEditDialog"
          }
        }, [_c('v-card', [_c('v-card-title', [_c('span', {
          staticClass: "text-h5"
        }, [_vm._v(_vm._s(_vm.currentAction))])]), _c('v-card-text', [_c('v-container', [_vm._v(" Stuff Here ")])], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "info darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.qEditDialog = !_vm.qEditDialog;
            }
          }
        }, [_vm._v(" Cancel ")]), _vm.currentAction === 'edit' ? _c('v-btn', {
          attrs: {
            "color": "success darken-1",
            "text": ""
          },
          on: {
            "click": function () {}
          }
        }, [_vm._v(" Save ")]) : _vm._e(), _vm.currentAction === 'delete' ? _c('v-btn', {
          attrs: {
            "color": "error darken-1",
            "text": ""
          },
          on: {
            "click": function () {}
          }
        }, [_vm._v(" Delete ")]) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.editRandomSubsetMcq(item);
            }
          }
        }, [_vm._v(" Edit ")]), _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "error"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteRandomSubsetMcq(item);
            }
          }
        }, [_vm._v(" Delete ")])];
      }
    }], null, false, 4047697733)
  })], 1)]) : _vm.testTypeSelection === 'TEST_MODE_EXACT' ? _c('div', [_c('span', [_vm._v(" Exact Test ")])]) : _c('div', [_c('span', [_vm._v("Please select an option to get more detailed choices")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }